// (user_id: Int!, input: !): 
import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_USER } from '../config/api';

export const UPDATE_ADMIN_USER = gql`
  mutation UpdateAdminUser(
    $user_id: Int!
    $input: AdminUserInput!
  ) {
    updateAdminUser(
      user_id: $user_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_ADMIN_USER}",
      method: "PATCH"
    ) {
      success
    }
  }
`;
