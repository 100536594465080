import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import toString from 'lodash/toString';
import { type AxiosRequestConfig } from 'axios';

export function isLoggedIn(): boolean {
  try {
    return (
      !isUndefined(sessionStorage) && !isNil(sessionStorage.getItem('token'))
    ) || Boolean(process.env.REACT_APP_AUTH_TOKEN);
  } catch (err) {
    // browser is in private mode
    return false;
  }
}

export function setAuthToken(token: string, admin = false): void {
  try {
    if (!isUndefined(sessionStorage)) sessionStorage.setItem(admin ? 'adminToken' : 'token', token);
  } catch (err) {
    // browser is in private mode
  }
}

export function getAuthToken(admin = false): string {
  try {
    return (
      isUndefined(sessionStorage) ? '' : sessionStorage.getItem(admin ? 'adminToken' : 'token')
    ) || (admin ? '' : toString(process.env.REACT_APP_AUTH_TOKEN));
  } catch (err) {
    // browser is in private mode
    return '';
  }
}

export const getAuthHeaders = () => ({
  headers: {
    Authorization: `Token ${getAuthToken()}`
  }
}) as unknown as AxiosRequestConfig;

export function removeAuthToken(): void {
  try {
    if (!isUndefined(sessionStorage)) sessionStorage.removeItem('token');
  } catch (err) {
    // browser is in private mode
  }
}

export function openMessage(messageId: string, userId?: string): boolean {
  try {
    return isUndefined(localStorage) ? true : !localStorage.getItem(`${messageId}${userId || ''}`);
  } catch (err) {
    // browser is in private mode
    return true;
  }
}

export function setOpenMessage(messageId: string, userId?: string): void {
  try {
    if (!isUndefined(localStorage)) localStorage.setItem(`${messageId}${userId || ''}`, 'true');
  } catch (err) {
    // browser is in private mode
  }
}

export function savePath(path: string): void {
  try {
    if (!isUndefined(localStorage)) localStorage.setItem('lastPath', path);
  } catch (err) {
    // browser is in private mode
  }
}

export function getPath(): string {
  try {
    return isUndefined(localStorage) ? '' : localStorage.getItem('lastPath') || '';
  } catch (err) {
    // browser is in private mode
    return '';
  }
}
