import get from 'lodash/get';
import find from 'lodash/find';
import reject from 'lodash/reject';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';
// EmPath UI Components
import { getCurrentSeconds, getCurrentUtc, getSecondsFromISO } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { OrgUnit } from '../models/orgUnit';
import defaultCustomerSettings from '../customers/settings.json';

export type Feature = 'salary_ranges';

export type DashboardAnalyticsChart = keyof (typeof defaultCustomerSettings.dashboard_analytics);
export type DashboardAnalyticsTabs = Record<DashboardAnalyticsChart, boolean>;

export interface ISkillDevelopmentResource {
  text: string;
  hover: string;
  link?: string | null;
}
export type SkillDevelopmentRes = ISkillDevelopmentResource | null;

export interface CustomerLink {
  text: string;
  link?: string | null;
}
export interface OtherResource extends CustomerLink {
  intl?: CustomerLink | null;
}
export interface OtherResources {
  support?: OtherResource[] | null;
  resources: OtherResource[];
}

export interface CustomerPopup extends CustomerLink {
  title?: string | null;
  inline_link?: string | null;
  button: string;
}
export interface CustomerTicker {
  id: number;
  ticker: CustomerLink | null;
  popup: CustomerPopup | null;
  valid_from?: string;
  valid_till?: string;
  supervisor_only?: boolean;
  leader_only?: boolean;
  employee_only?: boolean;
  contractor_only?: boolean;
  is_warning?: boolean;
  show_on_job_screen?: boolean;
}

export type RuntimeConfig = {
  // new config
  admin_ui?: boolean;
  export_my_plan?: boolean;
  courses?: boolean;
  skill_development_resources?: boolean;
  team_builder?: boolean;
  dev_plan?: boolean;
  opportunities?: boolean;
  devplan_opportunities?: boolean;
  redeployment?: boolean;
  dashboard_analytics?: boolean;
  tickers?: Record<string, boolean>;

  // old config
  disable_team_builder?: boolean;
  enable_courses?: boolean;
  disable_courses?: boolean;
  disable_skill_development_resources?: boolean;
  disable_dev_plan?: boolean;
  disable_opportunities?: boolean;
  disable_redeployment?: boolean;
  disable_dashboard_analytics?: boolean;

  // config for features
  release_all_features?: boolean;
  feature_release_date?: Record<Feature, string>;
};

const injectParams = /\{(\w+)\}/gu;
const SKILL_DEVELOPMENT_RESOURCES_EMPTY: SkillDevelopmentRes[][] = [];

export const customerSettingsFactory = (
  runtimeConfig?: RuntimeConfig,
  customerSettings: typeof defaultCustomerSettings = defaultCustomerSettings
) => {
  const SKILL_DEVELOPMENT_RESOURCES_DISABLED = Boolean(runtimeConfig?.disable_skill_development_resources) ||
    runtimeConfig?.skill_development_resources === false;
  return {
    // Logo size:
    LOGO_WIDTH: customerSettings.logo_width,
    LOGO_HEIGHT: customerSettings.logo_height,

    MANAGEMENT_LEVEL_FIRST: customerSettings.management_level_min,
    MANAGEMENT_LEVEL_LAST: customerSettings.management_level_max,

    HAS_INTERNAL_COURSE_LOGO: customerSettings.has_internal_course_logo as boolean,

    DOMESTIC_COUNTRIES: customerSettings.domestic_countries as string[],

    FIND_YOUR_GEO_ZONE_URL: customerSettings.find_your_geo_zone_url as string,
    INTERNATIONAL_SALARY_URL: customerSettings.international_salary_url as string,

    PRIVACY_NOTICE_URL: customerSettings.privacy_notice_url as string,
    HELP_URL: customerSettings.help_url as string,
    PROFILE_BUILDER_VIDEO_URL: customerSettings.profile_builder_video_url as string,

    SKILL_DEVELOPMENT_RESOURCES_DISABLED,
    SKILL_DEVELOPMENT_RESOURCES: (
      SKILL_DEVELOPMENT_RESOURCES_DISABLED ? SKILL_DEVELOPMENT_RESOURCES_EMPTY : customerSettings.skill_development_resources
    ) as SkillDevelopmentRes[][] | null,

    CUSTOMER_OTHER_RESOURCES: customerSettings.other_resources as OtherResources,

    CUSTOM_MESSAGES: customerSettings.messages as { en: Record<string, string> },

    isFeatureReleased: (feature: Feature): boolean => {
      if (runtimeConfig?.release_all_features) return true;
      const releaseDate = runtimeConfig?.feature_release_date?.[feature] ||
        (customerSettings.feature_release_date as (Record<Feature, string> | undefined))?.[feature];
      return !releaseDate || getSecondsFromISO(releaseDate) <= getCurrentSeconds();
    },

    getOrgUnitSplash: (orgUnit?: OrgUnit | null): boolean => Boolean(
      customerSettings.org_unit_splash && orgUnit?.code &&
      get(customerSettings.org_unit_splash as Record<string, boolean>, toLower(orgUnit.code))
    ),

    getCourseUrl: (code?: number | string | null) => code && customerSettings.course_url
      ? replace(customerSettings.course_url, '{id}', encodeURIComponent(code)) : undefined,

    getSkillCoursesUrl: (params?: Record<string, string | number | null | undefined> | null | undefined) => {
      if (!params || !params.abbr || !params.title || !customerSettings.skill_courses_url) return undefined;
      return replace(customerSettings.skill_courses_url, injectParams,
        (_match, p1) => encodeURIComponent(toString(params[p1])));
    },

    getOpenReqUrl: (code?: number | null) => code && customerSettings.open_req_url
      ? replace(customerSettings.open_req_url, '{id}', encodeURIComponent(code)) : undefined,

    getEmployeeContactUrl: (code?: string | null, email?: string | null) =>
      (code && customerSettings.employee_contact_url &&
        replace(customerSettings.employee_contact_url, '{id}', encodeURIComponent(code))
      ) || (email && `mailto:${email}`) || undefined,

    CUSTOMER_TICKERS: (runtimeConfig?.tickers
      ? reject(customerSettings.tickers, ({ id }) => runtimeConfig.tickers?.[toString(id)] === false)
      : customerSettings.tickers
    ) as CustomerTicker[],

    DA_TABS: (
      runtimeConfig?.disable_dashboard_analytics || runtimeConfig?.dashboard_analytics === false
      ? null : customerSettings.dashboard_analytics
    ) as Partial<DashboardAnalyticsTabs> | null | undefined
  };
};

export const getTicker = (
  supervisor?: boolean | null,
  leader?: boolean | null,
  employee?: boolean | null,
  contractor?: boolean | null,
  tickers?: CustomerTicker[] | null
): CustomerTicker => {
  const curUtcISO = toString(getCurrentUtc().toISO());
  return (tickers && find(tickers, ({
    ticker,
    popup,
    valid_from,
    valid_till,
    supervisor_only,
    leader_only,
    employee_only,
    contractor_only
  }: CustomerTicker) => Boolean(ticker || popup) &&
    (!supervisor_only || Boolean(supervisor)) &&
    (!leader_only || Boolean(leader)) &&
    (!employee_only || Boolean(employee)) &&
    (!contractor_only || Boolean(contractor)) &&
    (!valid_from || valid_from <= curUtcISO) &&
    (!valid_till || valid_till >= curUtcISO)
  )) || {
    id: 0,
    ticker: null,
    popup: null
  } as CustomerTicker;
};
