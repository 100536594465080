import { memo, useEffect, useState, useMemo, useContext, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useLazyQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { ADMIN_EMPLOYEE_QUERY } from '../graphql/AdminEmployee';
import { AdminEmployee, AdminEmployeeDocument, AdminJob } from '../graphql/types';
import { TalentEmployeeObject } from '../graphql/customTypes';
import { SKILL_LEVEL_MAX, Skill } from '../models/skill';
import { Job } from '../models/job';
import { GlobalContext } from '../context/global';
import ImpersonateButton from '../elements/ImpersonateButton';
import EmployeeTalentBand from '../v3/EmployeeTalentBand';
import JobDetailsItem from '../v3/JobDetailsItem';
import SkillsGrid from '../v3/SkillsGrid';
// SCSS imports
import { lightBg } from './AdminEmployeeDialog.module.scss';

type AdminEmployeeDialogProps = {
  employee?: AdminEmployee | null;
  onJobClick?: (code: string, job?: Job | AdminJob, context?: TalentEmployeeObject | AdminEmployee) => void;
  onClose: () => void;
  // for Strorybook only
  testPending?: boolean;
}

const AdminEmployeeDialogPropTypes = {
  // attributes
  employee: PropTypes.object as Validator<AdminEmployee>,
  onJobClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  // for Strorybook only
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity
const AdminEmployeeDialog: FunctionComponent<AdminEmployeeDialogProps> = ({
  employee: parentEmployee,
  onJobClick,
  onClose,
  testPending
}) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const { query: getEmployee, pending: pendingEmployee, failed, results: employee } = useQueryObject({
    data: undefined as unknown as AdminEmployee,
    key: 'adminEmployee',
    flatResults: true,
    lazyQuery: useLazyQuery(ADMIN_EMPLOYEE_QUERY as typeof AdminEmployeeDocument)
  });
  const pending = pendingEmployee || !employee || testPending;

  const { id, code: parentCode, first_name: parentFirstName, last_name: parentLastName } = parentEmployee || {};
  const parentTitle = getFullName(parentFirstName, parentLastName, parentCode);
  const {
    code, first_name, last_name, management_level, org, org_unit, email, skills,
    is_contractor, is_countable, is_esau_untouchable, is_inference_target, by_sso
  } = employee || {};
  const emplCode = code || parentCode;
  const title = getFullName(first_name, last_name, code);
  const skillsCount = size(skills);

  useEffect(() => {
    if (id) getEmployee?.({ variables: { employee_id: id } });
  }, [id, getEmployee]);

  const isOpen = Boolean(parentEmployee);

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const disabled = pending || testPending ? true : undefined;

  const requiresSkills = useMemo(() => sortBy(skills,
    ({ title: skillTitle, expected_level }) => `${SKILL_LEVEL_MAX - (expected_level || 0)}${skillTitle}`), [skills]);

  const details = useMemo(() => [
    ...isNil(management_level) ? [] : [{ label: 'role.admin.management_level', value: management_level }],
    ...isNil(is_contractor) ? [] : [{ label: 'role.admin.is_contractor', value: is_contractor }],
    ...isNil(email) ? [] : [{ label: 'role.admin.email', value: email }],
    ...isNil(org) ? [] : [{ label: 'role.admin.org', value: org.title }],
    ...isNil(org_unit) ? [] : [{ label: 'role.admin.org_unit', value: org_unit.title }],
    ...skillsCount < 1 ? [{ label: 'role.admin.skills', value: 0 }] : []
  ] as { label: string; value: number | string; }[], [skillsCount, management_level, is_contractor, email, org, org_unit]);

  const extra = useMemo(() =>
    [
      ...isNil(is_countable) ? [] : [{ label: 'role.admin.is_countable', value: is_countable }],
      ...isNil(is_esau_untouchable) ? [] : [{ label: 'role.admin.is_esau_untouchable', value: is_esau_untouchable }],
      ...isNil(is_inference_target) ? [] : [{ label: 'role.admin.is_inference_target', value: is_inference_target }],
      ...isNil(by_sso) ? [] : [{ label: 'role.admin.by_sso', value: by_sso }]
    ] as { label: string; value: number | string | ReactNode; }[],
    [is_countable, is_esau_untouchable, is_inference_target, by_sso]
  );

  const bands = [details, extra].map((band, index) => band.length >= 1 ? (
    <>
      {index === 1 ? <Divider/> : undefined}
      <CardSection
          compact flex
          dark={index === 0 ? true : undefined}
          className={index === 1 ? lightBg : undefined}
      >
        <Box
            py={2.5}
            flexGrow={1}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
        >
          {map(band, ({ label, value }, idx) => (
            <JobDetailsItem
                key={label}
                prompt={<FormattedMessage id={label}/>}
                value={value}
                divider={idx < band.length - 1}
            />
          ))}
        </Box>
      </CardSection>
      {index === 0 ? <Divider/> : undefined}
    </>
  ) : undefined);

  return mounted ? (
    <Dialog
        disableEnforceFocus
        maxWidth="xl"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={disabled ? undefined : onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardTitle
          title={(failed && (parentTitle || 'admin.employees.default_name')) ||
            (pending && (parentTitle || <Skeleton variant="text" width="12rem"/>)) ||
            title || 'admin.employees.default_name'}
          subheader={emplCode ? <ImpersonateButton large code={emplCode} disabled={pending}/> : undefined}
          withDivider
      />
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (mounted ? (
        <>
          <CardSection compact>
            <EmployeeTalentBand employee={employee} route={supvEmplPath} onJobClick={onJobClick} withoutDivider/>
          </CardSection>
          <Divider/>
          {bands[0]}
          {skillsCount >= 1 ? (
            <>
              <CardSection top>
                <BoxTypography pt={1} variant="subtitle1">
                  <FormattedMessage id="role.admin.skills"/>
                </BoxTypography>
              </CardSection>
              <SkillsGrid
                  plain
                  readOnly
                  manualOnly
                  withLink={false}
                  skills={(requiresSkills || []) as Skill[]}
              />
            </>
          ) : undefined}
          {bands[1]}
        </>
      ) : undefined)}
    </Dialog>
  ) : null;
};

AdminEmployeeDialog.propTypes = AdminEmployeeDialogPropTypes;

export default memo(AdminEmployeeDialog);
